import React from "react";
import { imageBasePath } from "../../../constants";

export default function WhoWeAre(props) {
  return (
    <div className="who-we-are-blk">
      <h3 className="heading2 title">Who we are</h3>
      <p className="paragraph">
        We are a distinguished Online Dance Academy dedicated to providing
        professional dance instruction, delivered by expert instructors, all
        within the convenience of your own home. <br />
        <br />
        Our online dance classes are meticulously designed to offer a live and
        interactive learning experience that seamlessly replicates the
        atmosphere of an in-person class. We prioritize the highest standards of
        instruction to ensure our clients feel no discernible difference from
        attending an offline class. <br />
        <br />
        Our journey commenced in April 2020 when the onset of the COVID pandemic
        confined us to our homes. During these challenging times, our vision
        emerged: to unite individuals, facilitate enjoyment with friends, and
        promote physical fitness, all from the comfort of one's home. This noble
        vision culminated in the creation of our exceptional dance company.
        {/* Learn dancing online and have fun while you get fit. <br />
        <br />
        We have carefully curated instructors across various genres of dance,
        who train you to become an expert dancer irrespective of the level you
        are in now. <br />
        <br />
        Book your slot now to experience fun online dancing. */}
      </p>
      <img className="bg-img" src={`${imageBasePath}dancing_emoji.svg`} />
    </div>
  );
}
